* {
	font-family: arial, sans-serif;
	font-size: 20px;
}
/* полоса прокрутки (скроллбар) */
::-webkit-scrollbar {
    width: 8px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
}
/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
    background-color: #999;
	border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #777;
}

.bg-main {
	background: url('//posobiepdd.bsrv.su/static/img/bg.jpg') no-repeat 0 20%;
	background-size: cover;
	background-color: #282F32;
}
.polytech-logo {
	position: absolute;
	z-index: 3;
	right: calc(50% - 400px);
	top: 5px;
	width: 60px;
	height: 60px;
	background: url('//posobiepdd.bsrv.su/static/img/polytech-logo.svg') no-repeat 50% 0;
	background-size: contain;
	background-color: #213b60;
	border-radius: 10px;
	display: block;
}
.pdd-logo {
	position: absolute;
	z-index: 3;
	left: calc(50% - 400px);
	top: 5px;
	width: 385px;
	height: 60px;
	padding-left: 70px;
	background: url('//posobiepdd.bsrv.su/static/img/pdd-logo.png') no-repeat 0 0;
	background-size: contain;
}
.pdd-logo div {
	font-weight: bold;
	background: #91a751;
	color: #fff;
	border-radius: 10px;
	text-align: center;
	padding: 3px;
	border: 3px solid #fff;
}
.content {
	position: absolute;
	z-index: 2;
	top: 70px;
	bottom: 50px;
	left: calc(50% - 400px);
	width: 800px;
	background: #fffc;
	border-radius: 10px;
	padding: 10px;
}
.content .content-left {
	float: left;
	width: 20%;
	height: 100%;
	border-right: 3px dashed #0005;
	padding-right: 10px;
}
.content .content-left .content-header {
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 5px;
	color: #0009;
}
.content .content-left .menu {
	width: 100%;
	height: calc(100% - 27px);
	overflow-y: scroll;
	list-style: none;
	padding: 0;
	margin: 0;
	
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.content .content-left .menu .menu-item {
	position: relative;
    display: block;
	text-align: center;
	margin: 3px 0;
	padding: 3px 6px;
	text-overflow: ellipsis;
    /*overflow: hidden;
	white-space: nowrap;*/
	text-decoration: none;
	text-transform: uppercase;
	color: #000;
	background-color: #fffc;
	border-radius: 5px;
}
.content .content-left .menu .menu-item:focus,
.content .content-left .menu .menu-item:hover {
	background-color: #91a75188;
}
.content .content-left .menu .menu-item.active {	
	background-color: #91a751;
	color: #fff;
}
.content .content-right {
	float: right;
	width: 77%;
	height: 100%;
	overflow-y: scroll;
	line-height: 1.5;
	text-align: justify;
	padding-right: 10px;
}
.content .content-right .content-header {
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 24px;
}
.footer {
	position: absolute;
	z-index: 2;
	bottom: 5px;
	left: calc(50% - 200px);
	width: 400px;
	background: #0008;
	color: #fff9;
	border-radius: 10px;
	padding: 10px;
	font-size: 14px;
	text-align: center;
	font-weight: bold;
}
@media (min-width: 1200px) {
	.content {
		left: calc(50% - 600px);
		width: 1200px;
	}
	.content .content-left {
		width: 20%;
	}
	.content .content-right {
		width: 77%;
	}
}
@media (max-width: 1199px) {
	.content {
		left: calc(50% - 400px);
		width: 800px;
	}
	.content .content-left {
		width: 25%;
	}
	.content .content-right {
		width: 72%;
	}
}

ol.level1 {
	counter-reset: lis1 calc(var(--s) - 1);
	list-style: none;
	padding-inline-start: 0;
}
ol.level1 > li::before {
	content: counter(lis1) '. ';
}
ol.level1 li {
	counter-increment: lis1;
}
ol.level2 {
	counter-reset: lis2 calc(var(--s) - 1);
	list-style: none;
	padding-inline-start: 40px;
}
ol.level2 > li::before {
	content: counter(lis2) ') ';
}
ol.level2 li {
	counter-increment: lis2;
}